/**
 * 课程管理 接口
 * author: LeiHao
 * createTime: 2023/07/06
 */

import $http from '@/plugins/axios';

// 获取课程列表
export const getCourseListApi = (params) => {
    return $http({
        url: '/admin/courseList.html', 
        method: 'GET',
        params
    });
};

// 发布课程
export const publishCourseApi = (params) => {
    return $http({
        url: '/admin/publishCourse.html', 
        method: 'GET',
        params
    });
};

// 取消课程发布
export const unPublishCourseApi = (params) => {
    return $http({
        url: '/admin/unPublishCourse.html', 
        method: 'GET',
        params
    });
};

// 课程详情
export const getCourseDetailApi = (params) => {
    return $http({
        url: '/admin/course.html', 
        method: 'GET',
        params
    });
};

// 获取选项
export const getOptionsApi = (data) => {
    return $http({
        url: '/admin/getCourseParamList.html', 
        method: 'GET',
        data
    });
};

// 添加或课程信息
export const createCourseApi = (data) => {
    return $http({
        url: '/admin/addCourse.html', 
        method: 'POST',
        data
    });
};

// 课程封面上传接口
export const getCourseCoverUploadParam = (params) => {
    return $http({
        url: '/admin/getCourseCoverUploadParam.html',
        method: 'GET',
        params
    });
};

// 修改课程信息
export const editCourseApi = (data) => {
    return $http({
        url: '/admin/editCourse.html', 
        method: 'POST',
        data
    });
};

// 上传接口
export const uploadFileApi = (data) => {    
    return $http({
        url: '/admin/getUpload.html', 
        method: 'GET',
        data
    });
};


// 获取用户课程
export const getUserCourseListApi = (params) => {
    return $http({
        url: '/admin/getUserCourser.html',
        method: 'GET',
        params
    });
};

// 获取用户课程信息
export const getUserCourseInfoApi = (params) => {
    return $http({
        url: '/admin/userCourserInfo.html',
        method: 'GET',
        params
    });
};

// 课件附件上传接口
export const getCourseAttachmentUploadParam = (params) => {
    return $http({
        url: '/admin/getCoursewareAttachmentUploadParam.html',
        method: 'GET',
        params
    });
};

// 获取课件列表数据
export const getCourseAttachmentListApi = (params) => {
    return $http({
        url: '/admin/getCoursewareList.html',
        method: 'GET',
        params
    });
};

// 编辑课件信息
export const editCourseAttachmentListApi = (data) => {
    return $http({
        url: '/admin/editCourseware.html',
        method: 'POST',
        data
    });
};

// 删除课件信息
export const deleteCourseAttachmentListApi = (params) => {
    return $http({
        url: '/admin/delCourseware.html',
        method: 'GET',
        params
    });
};

// 保存课件接口
export const saveAttachmentApi = (data) => {
    return $http({
        url: '/admin/addCourseware.html',
        method: 'POST',
        data
    });
};

// 获取考题设置信息
export const getExamQuestionInfoApi = (params) => {
    return $http({
        url: '/admin/getCourseExamConfig.html',
        method: 'GET',
        params
    });
};

// 设置考题
export const updateExamQuestionApi = (data) => {
    return $http({
        url: '/admin/editCourseExamConfig.html',
        method: 'POST',
        data
    });
};

// 删除课程
export const deleteCourseApi = (params) => {
    return $http({
        url: '/admin/delCourse.html',
        method: 'GET',
        params
    });
};

// 抽奖列表
export const api_getPrizeList = (params) => {
    return $http({
        url: '/admin/getEffectivePrizeList.html',
        method: 'GET',
        params
    });
};

// 设置抽奖
export const api_setPrize = (params) => {
    return $http({
        url: '/admin/setCoursePrize.html',
        method: 'GET',
        params
    });
};

// 上传附件
export const api_uploadFile = (params) => {
    return $http({
        url: '/admin/getCourseAttachmentUpload.html',
        method: 'GET',
        params
    });
};